* {
    box-sizing: border-box;
    min-width: 0;
}

body {
    min-height: 100dvh;
}

h1, h2, h3, h4 {
    text-wrap: balance;
}

p {
    margin: 0;
    text-wrap: pretty;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
}