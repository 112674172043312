* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}


body {
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--color-secondary);
}


html {
  box-sizing: border-box;
}

/* --- APP --- */


