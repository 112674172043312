.form_input {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 2rem; */
}

.form_input label {
    margin-bottom: .5rem;
    color: var(--color-text);
    font-size: 20px;
    font-weight: var(--font-semi-bold);
}

.form_input input {
    outline: none;
    padding: 1rem 2rem;
    background-color: var(--color-white);
    border: 2px solid var(--color-accent);
    border-radius: 3rem;
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: var(--font-bold);
    transition: border-color .3s ease-in-out;
}

.form_input input:hover,
.form_input input:focus {
    border-color: var(--color-light-green);
}


.form_input input[type="file"] {
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    font-weight: 600;
}