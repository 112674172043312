/* --- BUTTONS --- */

.button {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    font-size: 22px;
    font-weight: var(--font-light);
    transition: color .3s ease-in-out, column-gap .3s ease-in-out;
    cursor: pointer;
}

.button:hover {
    color: var(--color-primary);
    column-gap: 1.5rem;
}

.button.continue {
    padding: .5rem .5rem .5rem 1.25rem;
    border: 1px solid var(--color-primary);
    border-radius: 3rem;
}

.button i {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    font-size: 16px;
    transition: border-color .3s ease-in-out, color .3s ease-in-out;
}

.button:hover i {
    border-color: var(--color-black);
    color: var(--color-black);
}

@media all and (max-width: 480px) {

    .button {
        justify-content: space-between;
        font-size: 18px;
    }

    .button.continue {
        border-width: 2px;
    }

    .button i {
        border-width: 2px;
    }

}

.simulador_debug {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: rgb(37, 161, 199);
    z-index: 9999;
}

.container-select-file {
    margin-bottom: 30px;
}

.container-select-file input[type="file"] {
    appearance: none;
    display: none;
    visibility: hidden;
    opacity: 0;
}

.btn-select-file {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    padding: .5rem 1rem;
    border: 1px solid var(--color-primary);
    border-radius: 3rem;
    margin-bottom: 30px;
}

.btn-select-file:hover {
    cursor: pointer;
    color: var(--color-primary);
}

.upload-icon, .delete-icon {
    margin: 0 8px;
    color: var(--color-primary);
    font-size: 18px!important;
}

/*NIE LOAD FILE*/
.btn-select-file-dni {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    padding: .5rem 1rem;
    border: 1px solid var(--color-black);
    border-radius: 3rem;
    margin-bottom: 30px;
}

#dni-adverso, #dni-reverso, #factura-end {
    appearance: none;
    display: none;
    visibility: hidden;
    opacity: 0;
}

.btn-select-file-dni:hover {
    cursor: pointer;
}

.upload-icon-dni, .delete-icon-dni {
    margin: 0 8px;
    color: var(--color-black);
    font-size: 18px!important;
}

.btn-select-file-nie {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    padding: .5rem 1rem;
    border: 1px solid var(--color-black);
    border-radius: 3rem;
}

.btn-select-file-nie:hover {
    cursor: pointer;
}

/*NIE LOAD FILE*/

.loading {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
}

.container-ball{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.loader_box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60px;
}

.loading-text{
    color: var(--color-primary);
    font-size: 1rem;
    padding-bottom: 10px;
}

.ball {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--color-primary);
    animation: bouncing 1s infinite ease-in-out;
    z-index: 2;
}

.shadow {
    position: absolute;
    width: 12px;
    height: 6px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    filter: blur(1px);
    animation: shadow 1s infinite ease-in-out;
}

.ball:nth-child(2) {
    animation-delay: 0.2s;
    left: 20px; /* Ajusta la posición horizontal para evitar superposición */
}

.ball:nth-child(3) {
    animation-delay: 0.4s;
    left: 40px; /* Ajusta la posición horizontal para evitar superposición */
}

.shadow:nth-child(2) {
    animation-delay: 0.2s;
    left: 20px; /* Ajusta la posición horizontal para evitar superposición */
}

.shadow:nth-child(3) {
    animation-delay: 0.4s;
    left: 40px; /* Ajusta la posición horizontal para evitar superposición */
}

@keyframes bouncing {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(-20px);
    }
}

@keyframes shadow {
    0% {
        transform: scale(0.6) translateY(0px);
        opacity: 0.4;
    }
    50% {
        transform: scale(1) translateY(0px);
        opacity: 0.6;
    }
    100% {
        transform: scale(0.6) translateY(0px);
        opacity: 0.4;
    }
}




