@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700&display=swap');

:root {

  /* Colores */

  --color-primary: #E38E0F;
  --color-secondary: #FFF4EA;
  --color-text: #0F0F0F;
  --color-dark-green: #08302C;
  --color-light-green: #8D892E;
  --color-white: #fff;
  --color-black: #000;

  /* Tipografía */

  --font-family: 'Outfit', sans-serif;
  --font-thin: 100;
  --font-extra-light: 200;
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-extra-bold: 800;

}