.screen{
    height: 100vh;
}



/* --- Screen 1 --- */

.screen-1 {
    position: relative; ;
    display: flex;
    background-color: var(--color-secondary);
}

@media all and (max-width: 1024px) {

    .screen-1 {
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        max-height: none;
    }

    .screen-1 .main__container {
        row-gap: 3rem;
    }
}

/* -- Main -- */

.screen-1 .main__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 4rem 3rem;
}

@media all and (max-width: 480px) {

    .screen-1 .main__container {
        row-gap: 2.5rem;
        padding: 3rem 2rem;
    }

}

/* -  Logo - */

.screen-1 .main__container--logo .logo {
    max-width: 250px;
}

@media all and (max-width: 768px) {

    .screen-1 .main__container--logo .logo {
        max-width: 200px;
    }

}

/* - Title - */

.screen-1 .main__container--title .h1-title {
    max-width: 50%;
    font-size: 40px;
    font-weight: var(--font-light);
    line-height: 1.3em;
}

@media all and (max-width: 1366px) {

    .screen-1 .main__container--title .h1-title {
        max-width: 40%;
        font-size: 36px;
    }

}

@media all and (max-width: 1024px) {

    .screen-1 .main__container--title .h1-title {
        max-width: 80%;
    }

}

@media all and (max-width: 768px) {

    .screen-1 .main__container--title .h1-title {
        max-width: 100%;
        font-size: 28px;
    }

}

/* - Buttons - */

.screen-1 .main__container--buttons {
    display: flex;
    column-gap: 1rem;
}

@media all and (max-width: 480px) {

    .screen-1 .main__container--buttons {
        flex-direction: column;
        row-gap: 1rem;
    }

}

/* -- Background -- */

.screen-1--background {
    position: absolute;
    top: -12.5%;
    right: 0;
    height: 125%;
    width: 50%;
    background-image: url('./assets/img/screen-1_background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
}
@media all and (max-width: 1024px) {

    .screen-1--background {
        order: -1;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 450px;
        background-position: center top;
        border-radius: 0;
    }

}

@media all and (max-width: 480px) {

    .screen-1--background {
        height: 250px;
    }

}

/* --- Screen 2 --- */

.screen-2 {
    display: flex;
}

@media all and (max-width: 1024px) {

    .screen-2 {
        flex-direction: column;
    }

}

/* -- Sidebar -- */

.screen-2 .sidebar {
    position: relative;
    width: 25%;
    background-color: var(--color-primary);
    background-image: url('./assets/img/TEMC_Deco-2.png');
    background-size: 35%;
    background-position: top right;
    background-repeat: no-repeat;
}

@media all and (max-width: 1366px) {

    .screen-2 .sidebar {
        width: 35%;
    }

}

@media all and (max-width: 1024px) {

    .screen-2 .sidebar {
        width: 100%;
    }

}

.screen-2 .sidebar__container {
    padding: 4rem 3rem;
}

@media all and (max-width: 1024px) {

    .screen-2 .sidebar__container {
        padding: 4rem;
    }

}

@media all and (max-width: 480px) {

    .screen-2 .sidebar__container {
        padding: 3rem 2rem;
    }

}

/* -  Logo - */

.screen-2 .sidebar__container--logo {
    margin-bottom: 4rem;
}

@media all and (max-width: 1366px) {

    .screen-2 .sidebar__container--logo {
        margin-bottom: 3rem;
    }

}

.screen-2 .sidebar__container--logo .logo {
    max-width: 175px;
}

/* - Title - */

.screen-2 .sidebar__container--title .h2-title {
    color: var(--color-secondary);
    font-size: 40px;
    font-weight: var(--font-semi-bold);
    line-height: 1.3em;
}

@media all and (max-width: 768px) {

    .screen-2 .sidebar__container--title .h2-title {
        font-size: 32px;
    }

}

/* - Subtitle - */

.screen-2 .sidebar__container--subtitle .subtitle {
    color: var(--color-secondary);
    font-size: 24px;
    font-weight: var(--font-light);
}

@media all and (max-width: 768px) {

    .screen-2 .sidebar__container--subtitle .subtitle {
        font-size: 20px;
    }

}

.screen-2 .sidebar__container--info-image {
    margin-top: 1rem;
}

@media all and (max-width: 1366px) {

    .screen-2 .sidebar__container--info-image {
        margin-top: 3rem;
    }

}

/* - Background - */

.sidebar .sidebar__container--background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 350px;
    height: 350px;
    background-image: url('./assets/img/TEMC_Deco-1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom left;
}

@media all and (max-width: 1366px) {

    .sidebar .sidebar__container--background {
        width: 250px;
        height: 250px;
    }

}





@media all and (max-width: 480px) {

    .sidebar .sidebar__container--background {
        width: 200px;
        height: 200px;
        opacity: .15;
    }

}

/* -- Main -- */

.screen-2 .main {
    width: 75%;
}

@media all and (max-width: 1366px) {

    .screen-2 .main {
        width: 65%;
    }

}

@media all and (max-width: 1024px) {

    .screen-2 .main {
        width: 100%;
    }

}

.screen-2 .main__container {
    width: 100%;
    height: 100%;
    padding: 4rem 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--color-secondary);
}

@media all and (max-width: 1366px) {

    .screen-2 .main__container {
        padding: 4rem 8rem;
    }

}

@media all and (max-width: 1024px) {
    
    .screen-2 .main__container {
        row-gap: 3rem;
        padding: 6rem 4rem;
    }

}

@media all and (max-width: 480px) {
    
    .screen-2 .main__container {
        row-gap: 2.5rem;
        padding: 3rem 2rem;
    }

}

/* - Steps - */

.main__container--steps {
    display: flex;
    align-items: center;
}

.main__container--steps .steps--number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border: 1px solid var(--color-primary);
    border-radius: 2rem;
    color: var(--color-primary);
    font-size: 14px;
}

.main__container--steps .steps--number.active {
    background-color: var(--color-primary);
    color: var(--color-secondary);
}

.main__container--steps .steps--line {
    height: 2px;
    width: 46%;
    background-color: var(--color-primary);
}

@media all and (max-width: 480px) {

    .main__container--steps .steps--line {
        width: 40%;
    }

}

/* - Form - */

.main__container--form .form {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

@media all and (max-width: 768px) {

    .main__container--form .form {
        row-gap: 1.5rem;
    }

}

/* Row */

.main__container--form .form .form__row {
    display: flex;
    column-gap: 2rem;
    width: 100%;
    align-items: center;
}

@media all and (max-width: 768px) {

    .main__container--form .form .form__row {
        flex-direction: column;
        row-gap: 1.5rem;
    }

}

/* Text */

.main__container--form .form .form__row .text {
    max-width: 65%;
    font-size: 18px;
    font-weight: var(--font-light);
}

@media all and (max-width: 768px) {

    .main__container--form .form .form__row .text {
        max-width: 100%;
    }

}

.main__container--form .form .form__row .text.smaller {
    max-width: 45%;
}

@media all and (max-width: 768px) {

    .main__container--form .form .form__row .text.smaller {
        max-width: 100%;
    }

}

/* Inputs */

.main__container--form .form .form__row input {
    outline: none;
    width: 100%;
    padding: 0.825rem 1.5rem;
    background-color: transparent;
    border: 1.5px solid var(--color-black);
    border-radius: 3rem;
    font-size: 1rem;
    font-weight: var(--font-light);
    transition: border-color .3s ease-in-out;
}

.main__container--form .form .form__row input::placeholder {
    opacity: 1;
}

.main__container--form .form .form__row input:hover {
    border-color: var(--color-primary);
}

.main__container--form .form .form__row input:focus::placeholder {
    opacity: .25;
}

@media all and (max-width: 480px) {

    .main__container--form .form .form__row input {
        font-size: 18px;
    }

}

/* Input - File */

.main__container--form .form .form__row input[type="file"] {
    width: auto;
    padding: 0;
    background-color: #f7f7f7;
    border: none;
    border-radius: 0;
}

/* Checkboxes */

.main__container--form .form .form__row--checkboxes {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.main__container--form .form .form__row--checkbox {
    display: flex;
    align-items: center;
    column-gap: .5rem;
}

.main__container--form .form .form__row--checkbox input {
    appearance: none;
    cursor: pointer;
    width: 15px;
    height: 15px;
    border: 1px solid var(--color-primary);
    border-radius: 2rem;
    transition: background-color .3s ease-in-out, border-width .153s ease-in-out;
}

.main__container--form .form .form__row--checkbox input:hover {
    border-width: 1.5px;
}

.main__container--form .form .form__row--checkbox input:checked {
    background-color: var(--color-primary);
}

.main__container--form .form .form__row--checkbox label {
    font-weight: var(--font-light);
}

.main__container--form .form .form__row--checkbox label a {
    text-decoration: underline;
}

.main__container--form .form .form__row--checkbox label a:hover {
    font-weight: var(--font-regular);
}

/* - Summary - Table - */

.main__container--summary {
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
}

.main__container--summary .text {
    margin-bottom: 1rem;
    font-size: 18px;
    font-weight: var(--font-light);
}

.main__container--summary .table .table__right .text {
    text-align: right;
}

.main__container--summary .text:last-of-type {
    margin-bottom: 0;
}

@media all and (max-width: 1366px) {

    .main__container--summary .text {
        font-size: 16px;
    }

}

@media all and (max-width: 480px) {

    .main__container--summary .table .text {
        font-size: 14px;
    }

}

/* Row */

.main__container--summary .table .table__row {
    display: flex;
    align-items: center;
    width: 100%;
    padding: .75rem 1rem;
    border-bottom: 1.5px solid var(--color-black);
}

@media all and (max-width: 480px) {

    .main__container--summary .table .table__row {
        padding: 1rem 0;
    }

}

.main__container--summary .table .table__row:last-of-type {
    border: none;
}

.main__container--summary .table .table__row.header {
    justify-content: end;
}

/* Left & Right */

.main__container--summary .table .table__row .table__left,
.main__container--summary .table .table__row .table__right {
    display: flex;
    width: 50%;
}

@media all and (max-width: 480px) {

    .main__container--summary .table .table__row .table__left {
        width: 40%;
    }
    
    .main__container--summary .table .table__row .table__right {
        width: 60%;
    }

}

/* Column */

.main__container--summary .table .table__row .table__column {
    display: flex;
    width: 50%;
}

@media all and (max-width: 480px) {

    .main__container--summary .table .table__row .table__column {
        width: 85%;
    }

}

.main__container--summary .table .table__row .table__right .table__column {
    justify-content: end;
}

.main__container--summary .table .table__row .table__column .text,
.main__container--summary .table .table__row .table__column .quantity {
    width: 100%;
}

.main__container--summary .table .table__row .table__column .quantity {
    color: var(--color-light-green);
    font-size: 24px;
    text-align: right;
}

@media all and (max-width: 1366px) {

    .main__container--summary .table .table__row .table__column .quantity {
        font-size: 22px;
    }

}

@media all and (max-width: 480px) {

    .main__container--summary .table .table__row .table__column .quantity {
        font-size: 20px;
    }

}

/* - Buttons - */

.screen-2 .main__container--buttons {
    display: flex;
    justify-content: space-between;
}

.screen-2 .main__container--buttons .buttons--left,
.screen-2 .main__container--buttons .buttons--right {
    display: flex;
    column-gap: 1rem;
}

@media all and (max-width: 480px) {

    .screen-2 .main__container--buttons .buttons--right {
        flex-direction: column;
        row-gap: 1rem;
    }

}



/* --- Screen 3 --- */

.screen-3 {
    /* overflow-y: hidden; */
    background-color: var(--color-light-green);
    background-image: url('./assets/img/TEMC_Background.png');
    background-size: 40%;
    background-position: center right;
    background-repeat: no-repeat;
}

@media all and (max-width: 480px) {

    .screen-3 {
        background-size: 45%;
        background-position: bottom right;
    }

}

/* -- Main -- */

.screen-3 .main__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 4rem 3rem;
}

@media all and (max-width: 480px) {

    .screen-3 .main__container {
        padding: 3rem 2rem;
    }

}

/* -  Logo - */

.screen-3 .main__container--logo {
    margin-bottom: 5rem;
}

@media all and (max-width: 480px) {

    .screen-3 .main__container--logo {
        margin-bottom: 3rem;
    }

}

.screen-3 .main__container--logo .logo {
    max-width: 175px;
}

/* - Title & Subtitle - */

.screen-3 .main__container--title, 
.screen-3 .main__container--subtitle {
    max-width: 48%;
}

@media all and (max-width: 1024px) {

    .screen-3 .main__container--title,
    .screen-3 .main__container--subtitle {
        max-width: 55%;
    }

}

@media all and (max-width: 480px) {

    .screen-3 .main__container--title,
    .screen-3 .main__container--subtitle {
        max-width: 100%;
    }

}

/* Title */

.screen-3 .main__container--title .h2-title {
    color: var(--color-secondary);
    font-size: 40px;
    font-weight: var(--font-semi-bold);
    line-height: 1.3em;
}

@media all and (max-width: 480px) {

    .screen-3 .main__container--title .h2-title {
        font-size: 32px;
    }

}

/* - Subtitle - */

.screen-3 .main__container--subtitle .subtitle {
    color: var(--color-secondary);
    font-size: 24px;
    font-weight: var(--font-light);
}

@media all and (max-width: 480px) {

    .screen-3 .main__container--subtitle .subtitle {
        font-size: 22px;
    }

}