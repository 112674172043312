.debugBanner {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   background-color: rgb(37, 161, 199);
   padding: 10px;
   text-align: center;
   font-weight: bold;
   z-index: 1000;
}